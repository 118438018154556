const getThumbnailUrl = (name, path) => {
  return (
    "https://morgangibsonphotography.twic.pics/" + path + "/" + name + "?twic=v1/resize=2000"
  );
};

const getFullSizeUrl = (name, path) => {
  return "https://bilder.morgangibsonphotography.tech/" + path + "/" + name;
};

export { getThumbnailUrl, getFullSizeUrl };
