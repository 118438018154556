import React, { useState, Fragment } from "react";
import ShareIcon from "@material-ui/icons/Share";
import MaterialButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, analytics } from "../../utils/firebase";
import { logEvent } from "firebase/analytics";

const Container = styled.div``;

const Share = (props) => {
  const [resultMessage, setResultMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(auth);

  return (
    <Container>
      <Fragment>
        <MaterialButton
          disabled={!!resultMessage}
          onClick={async () => {
            logEvent(analytics, 'share_button_clicked');

            if (!user) {
              return;
            }

            setIsLoading(true);

            // eslint-disable-next-line no-undef
            const clipboardItem = new ClipboardItem({
              "text/plain": user
                .getIdToken()
                .then((token) => {
                  const requestOptions = {
                    method: "POST",
                    headers: { Authorization: `Bearer ${token}` },
                  };
                  return fetch(
                    `https://us-central1-morgan-gibson-foto.cloudfunctions.net/app/generate?images=${props.images}`,
                    requestOptions
                  );
                })
                .then((result) => result.json())
                .then((result) => {
                  /**
                   * We have to return an empty string to the clipboard if something bad happens, otherwise the
                   * return type for the ClipBoardItem is incorrect.
                   */
                  if (!result) {
                    return new Promise(async (resolve) => {
                      resolve(new Blob[``]());
                    });
                  }

                  const copyText = result.url;
                  return new Promise(async (resolve) => {
                    setIsLoading(false);
                    setResultMessage("Link kopiert");
                    setTimeout(() => {
                      setResultMessage();
                    }, 2000);
                    resolve(new Blob([copyText], { type: "text/plain" }));
                  });
                })
                .catch((error) => {
                  setIsLoading(false);
                  setResultMessage(error.message);
                  setTimeout(() => {
                    setResultMessage();
                  }, 2000);
                  console.error(error);
                }),
            });

            // Now, we can write to the clipboard in Safari
            navigator.clipboard.write([clipboardItem]);
          }}
        >
          {resultMessage}
          {!resultMessage && !isLoading && <ShareIcon />}
          {isLoading && <CircularProgress size={24} />}
        </MaterialButton>
      </Fragment>
    </Container>
  );
};

export default Share;
