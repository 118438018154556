import React, { Fragment } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 15px;
  padding: 17px;
  margin: 3vw auto;
`;

const InstagramLogo = styled.img`
width: 26px;
height 26px;
`;

const Footer = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Container>
      <Fragment>
        <a href="http://instagram.com/morgangibsonphotography">
          <InstagramLogo
            src={require("../../assets/instagram-sketched.png")}
            alt="Instagram"
          />
        </a>
        <p>MORGAN GIBSON PHOTOGRAPHY</p>
        {matches ? (
          <p>
            telefon: 944 79 391 | mail:{" "}
            <a href="mailto:morgangphoto@gmail.com">morgangphoto@gmail.com</a>
          </p>
        ) : (
          <p>
            telefon: 944 79 391
            <br />
            <a href="mailto:morgangphoto@gmail.com">morgangphoto@gmail.com</a>
          </p>
        )}
      </Fragment>
    </Container>
  );
};

export default Footer;
