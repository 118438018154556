// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrel4WOfV2rqrdDRk5HWf0RI_Bc0tTfH4",
  authDomain: "morgan-gibson-foto.firebaseapp.com",
  projectId: "morgan-gibson-foto",
  storageBucket: "morgan-gibson-foto.appspot.com",
  messagingSenderId: "698416623587",
  appId: "1:698416623587:web:0552a489fcde76a1d2bad6",
  measurementId: "G-DS7E0R7D96"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };
